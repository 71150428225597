import React from 'react'

interface IIconV1Overview {
  color?: string
  size?: number
}

const IconV1Overview = React.forwardRef<SVGSVGElement, IIconV1Overview>(({ color = 'currentColor', size = 24, ...rest }, ref) => {
  return (
    <svg ref={ref} xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 18 18' {...rest}>
      <path
        d='M14.967 12.7458C15.847 11.4412 16.3615 9.87069 16.3615 8.18204C16.3615 3.67073 12.6913 0 8.18075 0C3.67015 0 0 3.67073 0 8.18204C0 12.6934 3.67015 16.3641 8.18075 16.3641C9.86773 16.3641 11.4368 15.8502 12.7406 14.9713L15.3085 17.5398C15.9221 18.1534 16.9201 18.1534 17.5337 17.5398C18.1554 16.9261 18.1554 15.9279 17.5337 15.3061L14.967 12.7458ZM8.18075 14.7277C4.57211 14.7277 1.63615 11.7913 1.63615 8.18204C1.63615 4.57284 4.57211 1.63641 8.18075 1.63641C11.7894 1.63641 14.7254 4.57284 14.7254 8.18204C14.7254 11.7913 11.7894 14.7277 8.18075 14.7277Z'
        fill={color}
      />
      <path d='M4.08582 6.55115H5.72197V11.4497H4.08582V6.55115Z' fill={color} />
      <path d='M7.36304 4.91479H8.99919V11.4498H7.36304V4.91479Z' fill={color} />
      <path d='M10.6394 8.18176H12.2756V11.4497H10.6394V8.18176Z' fill={color} />
    </svg>
  )
})

export default IconV1Overview
