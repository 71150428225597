import { useWeb3React } from '@web3-react/core'
import { accountRoute } from 'constants/routes'
import usePlatform from 'contexts/platform'
import useResponsive from 'hooks/common/useResponsive'
import useTheme from 'hooks/common/useTheme'
import { useWallet } from 'modules/wallet-module'
import { useTranslation } from 'next-i18next'
import { Flex, Text } from 'rebass/styled-components'
import { AnimatePresence, Button, IconNotification, IconWallet, MotionFlex, Spacer, useDrawer } from 'theme/ui'
import NavButtonAccount from 'theme/ui/common/nav-button/nav-button-account'
import NotificationLoader from 'theme/ui/common/notification-loader'
import Popover from 'theme/ui/common/popover'

import ExternalLink from 'components/common/external-link'
// import NewProfileMenuNotification from 'components/common/new-profile-menu-notification'
import Sidebar from 'components/common/sidebar'

const getTransition = ({ duration = 0.3 }) => ({
  transition: {
    duration: duration,
    ease: 'easeInOut'
  }
})

const motionVariants = {
  badgeInitial: {
    scale: 0
  },
  badgeAnimate: {
    scale: 1,
    ...getTransition({ duration: 0.2 })
  },
  badgeExit: {
    scale: 0,
    ...getTransition({ duration: 0.2 })
  }
}

const Web3Status: React.FC = () => {
  const { t } = useTranslation('common')
  const { t: tc } = useTranslation('common')
  const { colors } = useTheme()
  const { account } = useWeb3React()
  const [toggleSidebar, , setActivePane] = useDrawer(<Sidebar />)
  const { unreadedNotifications } = usePlatform()
  const { isTablet } = useResponsive()
  const { showSpinner } = useWallet()

  return (
    <>
      {/* <NewProfileMenuNotification /> */}
      <Flex alignItems='center' justifyContent='flex-end'>
        <Popover placement='bottom' overlay={t('Visit BendDAO protocol V2')} background='primary'>
          <ExternalLink
            href='https://app.benddao.xyz/'
            target='_blank'
            hideIcon
            sx={{
              position: 'relative',
              display: 'block',
              bg: 'blue.100',
              color: 'primary',
              fontSize: 'sm',
              fontWeight: 'bold',
              p: ['4.5px 8.5px', null, null, null, null, '4.5px 14.5px'],
              borderRadius: 'sm',
              '&:hover': {
                bg: 'blue.200',
                opacity: 1
              }
            }}
          >
            {isTablet ? 'BendDAO V2' : 'V2'}
            <Flex
              sx={{
                position: 'absolute',
                top: [-5],
                right: [-10],
                bg: 'primary',
                borderColor: 'primary',
                color: 'white',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 'sm',
                transform: 'rotate(20deg)',
                height: 14,
                width: 33
              }}
            >
              <Text fontSize={8} textAlign='center'>
                NEW
              </Text>
            </Flex>
          </ExternalLink>
        </Popover>
        <Spacer size='md' />
        <NavButtonAccount {...accountRoute} />
        <Spacer size={isTablet ? 'md' : 'sm'} />
        <Popover placement='bottom' overlay={t('label.wallet')} background='primary'>
          <Button
            size='icon'
            color='blue.100'
            p={5.5}
            buttonStyles={{ position: 'relative' }}
            onClick={() => {
              toggleSidebar()
              setActivePane(0)
            }}
          >
            <AnimatePresence>
              {showSpinner && (
                <MotionFlex
                  variant='button-badge'
                  variants={motionVariants}
                  initial='badgeInitial'
                  animate='badgeAnimate'
                  exit='badgeExit'
                  sx={{
                    bg: 'primary',
                    color: 'white'
                  }}
                >
                  <NotificationLoader size={12} />
                </MotionFlex>
              )}
            </AnimatePresence>
            <IconWallet size={20} color={colors.primary} />
          </Button>
        </Popover>
        {isTablet && account && (
          <>
            <Spacer size='md' />
            <Popover placement='bottom' overlay={tc('label.notifications')}>
              <Button
                size='icon'
                color='blue.100'
                p={5.5}
                onClick={() => {
                  toggleSidebar()
                  setActivePane(1)
                }}
                buttonStyles={{ position: 'relative' }}
              >
                <AnimatePresence>
                  {(unreadedNotifications === '99+' || (typeof unreadedNotifications === 'number' && unreadedNotifications > 0)) && (
                    <MotionFlex
                      variant='button-badge'
                      variants={motionVariants}
                      initial='badgeInitial'
                      animate='badgeAnimate'
                      exit='badgeExit'
                      sx={{
                        bg: 'red.1000',
                        color: 'white'
                      }}
                    >
                      {unreadedNotifications}
                    </MotionFlex>
                  )}
                </AnimatePresence>
                <IconNotification size={20} color={colors.primary} />
              </Button>
            </Popover>
          </>
        )}
      </Flex>
    </>
  )
}

export default Web3Status
